@use "src/utils" as *;
@use "src/global" as *;

.main {
  @extend %context;
  margin-top: 20px;

  .title {
    @include heading(14px, 500);
    margin-bottom: 10px;
  }
}

.select {
  width: 100%;
  margin-bottom: 10px;
}


.btn {
  @include primary-btn;
  width: 280px;
  margin-top: 30px;

  @include sm {
    width: 100%;
  }
}