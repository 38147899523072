@use "src/utils" as *;
@use "src/global" as *;

.wrapper {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}

.infoBar {
  display: flex;
  align-items: center;
  padding: 20px 16px;
  background-color: #F5F5F5;
  margin: 30px 0 15px 0;
  gap: 8px;

  .avatar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }

  .name {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .comment {
    color: $primary-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;
    text-decoration: none;
    flex-shrink: 0;
  }
}

.panel {
  margin: 15px 0;
}

.list {
  flex-shrink: 0;
  width: 100%;
  align-self: center;
}
