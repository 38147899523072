@use "src/utils" as *;
@use "src/global" as *;

.breadcrumb {
  @include main;
  background-color: $background-color;
  height: 60px;
  padding: 22px 16px;
  box-sizing: border-box;

  .breadcrumb_box {
    @include box(unset);
    background-color: $background-color;

    //styleName: F12-Light-100%;
    font-family: Noto Sans TC;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    letter-spacing: 1px;


    .light {
      color: #78716C;
      text-decoration: none;
    }

    .dark {
      color: #292524;
    }
  }
}
