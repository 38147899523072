@use "src/utils" as *;
@use "src/global" as *;

.header {
  @extend %context;
  margin-top: 20px;
  margin-bottom: 10px;
}

.box {
  background-color: $background-color;
  padding: 20px;

  .title {
    @include heading(14px, 500)
  }
  
  .content {
    @extend %context;
    color: $subheading-color;
  }
}