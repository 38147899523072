@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;

  @include sm {
    flex-direction: column;
  }

  .leftCol {
    flex: 0 1 480px;

    @include md {
      flex: 1;
    }

    .imgBox {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 10px;
      }

      .img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .name {
      @extend %cat-name;

      @include sm {
        text-align: left;
      }
    }
  }

  .rightCol {
    flex: 1;
    position: relative;
  }
}

.primaryColor {
  color: $primary-color;
}

.dangerColor {
  color: $danger-color;
}

.tag {
  @extend %context-light;
  color: $primary-color;
  padding: 8px 16px;
  border: 1px solid $primary-color;
  border-radius: 2px
}


.metaBox {
  @extend %context;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include sm {
    align-items: flex-start;
  }

  .btn {
    @include primary-btn;
    margin-top: 50px;

    @include sm {
      display: none;
    }
  }
}


.cardBox {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(212px, 1fr));
  column-gap: 16px;
  row-gap: 16px;

  @include md {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  .imgBox {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    cursor: pointer;

    .img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }
}

.blockBtn {
  @include primary-btn;
  display: none;

  @include sm {
    display: block;
    position: sticky;
    left: 0;
    bottom: 50px;
    width: 100%;
    margin-top: 30px;
  }
}

.name {
  @include heading(14px, 500);
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.createModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
  row-gap: 16px;

  .content {
    width: 100%;
    display: flex;
    row-gap: 32px;
    column-gap: 16px;
    flex-wrap: wrap;
  }

  .upload {
    flex: 1;
    min-width: min(100%, 300px);
    min-height: 200px;

    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .uploadImg {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .input {
    flex: 1 400px;
    min-height: 300px;
  }

  .reportPhotoNote {
    font-weight: 300;
    margin-top: 12px;
  }

  .modalBtn {
    @include primary-btn;
  }
}

.dataModal {
  .img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .content {
    @include heading;
    margin-bottom: 12px;
  }

  .createAt {
    @include heading;
    color: $subheading-color;
  }
}
