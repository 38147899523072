@use "src/utils" as *;
@use "src/global" as *;

.vi__container {
  width: min(100%, 340px);
  display: flex;
  justify-content: space-between;
}


.vi {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  position: absolute;
  color: transparent;
  background: transparent;
  caret-color: transparent;
  outline: none;
  border: 0 none transparent;
}

.vi::-ms-reveal,
.vi::-ms-clear {
  display: none;
}

.vi::selection {
  background: transparent;
}

/* :where() gives the styles specificity 0, which makes them overridable */
:where(.vi__container) {
  position: relative;
  display: flex;
  gap: 8px;
  height: 50px;
  width: 300px;
}

:where(.vi__character) {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 36px;
  border: 1px solid $primary-color;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
  font-family: Noto Sans TC;
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
}

:where(.vi__character_inactive) {
  color: dimgray;
  border: 1px solid $border-color;
}

:where(.vi__character_selected) {
  border: 2px solid $primary-color;
}
