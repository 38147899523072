.main {
  width: 100%;
  height: 100%;

  .header {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    padding: 10px;
  }

  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .redTxt {
    color: #ff4d4f;
  }

  :global(.ant-table-thead .ant-table-cell) {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  :global(.ant-table-row-level-0 .ant-table-cell) {
    &:nth-last-of-type(3) {
      text-align: center;
    }
  }

  :global(.ant-pagination) {
    align-items: center;
  }
}