.main {
  min-width: 300px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #D6D3D1;
  padding: 0px 16px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #3F3F46;

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .percentage {
    margin: 0 10px;
    font-size: 30px;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: 0px;
    text-align: center;
  }
}
