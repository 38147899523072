.main {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 10px;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .tabs {
    flex: 1 0;

    :global(.ant-tabs-content-holder) {
      display: flex;
      flex-direction: column;
    }

    :global(.ant-tabs-content) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    :global(.ant-tabs-tabpane) {
      overflow-y: auto;
      height: calc(100vh - 165px);
    }

    :global(.ant-tabs-tabpane-active) {
      display: flex;
      flex-direction: column;
    }
  }
}
