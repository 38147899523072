@use "src/utils" as *;
@use "src/global" as *;

$color_type_0: rgb(168, 162, 158);
$color_type_1: rgb(63, 63, 70);

$padding_type_0: 13px 16px 12px 16px;

$font_type_0: 700 16px/1.39 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 400 12px/1.39 "Noto Sans TC", Helvetica, Arial, serif;

.unnamed {
  display: flex;
  flex-direction: column;
  padding: $padding_type_0;
  position: relative;
}
.title {
  margin: 0;
  align-items: center;
  color: $color_type_1;
  display: flex;
  font: $font_type_0;
  justify-content: center;
  letter-spacing: 0px;
  min-height: 28px;
  text-align: center;
}
.subTitle {
  align-items: center;
  color: $color_type_0;
  display: flex;
  font: $font_type_1;
  justify-content: center;
  letter-spacing: 0px;
  text-align: center;
}
.activeSubtitle {
  color: #00C2D6;
}
.activeTitle {
  color: $primary-color;
}