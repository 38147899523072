@use "src/utils" as *;
@use "src/global" as *;

$bg_color_type_0: rgba(63, 63, 70, 0.97);
$padding_type_0: 0; // 30px 0px 30px 0px;
$padding_type_1: 20px 20px 20px 20px;
$font_type_0: 400 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 300 12px/1 "Noto Sans TC", Helvetica, Arial, serif;

.content_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: $bg_color_type_0;
  z-index: 100;
  touch-action: none;
}
.mainWrapper {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(7, 1fr) 30px 60px;
  padding: 37px 0;
}
.menuTitles {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.close-button {
  grid-row: 9 / 9;
  background: var(--src) center center / 100% 100% no-repeat;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  opacity: 0.8;
  padding: $padding_type_1;
  transition-duration: 0.3s;
  transition-property: opacity;
  cursor: pointer;
}
.close-button:hover {
  opacity: 1;
}
.icon {
  height: 20px;
  object-fit: cover;
  width: 20px;
}

.menuItem {
  display: flex;
  flex-direction: column;
  opacity: 0.4;
  padding: $padding_type_0;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #3f3f46;
  }
}
.title {
  color: #FFF;
  display: flex;
  font: $font_type_0;
  justify-content: center;
  letter-spacing: 0px;
  text-align: center;
}
.subtitle {
  color: #FFF;
  display: flex;
  font: $font_type_1;
  justify-content: center;
  letter-spacing: 1px;
  text-align: center;
}