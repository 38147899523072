@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 30px;

  @include sm {
    flex-direction: column;
  }

  .leftCol {
    flex: 0 1 480px;

    @include md {
      flex: 1;
    }

    .imgBox {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 10px;
      }

      .img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .name {
      @extend %cat-name;
    }


  }

  .rightCol {
    flex: 1;
  }
}

.name {
  font-size: 28px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: 1px;
  text-align: left;
  color: #3F3F46;
}

.text {
  @extend %context;
}

.heading {
  @include heading(14px, 500);
  margin: 16px 0;
}

.mb {
  margin-bottom: 16px;
}

.steps {
  margin: 40px 0;
  max-width: 600px;

  @include sm {
    margin: 20px 0;
  }

  .items {
    font-size: 12px;
    font-weight: 300;
    white-space: pre-line;

    @include sm {
      white-space: unset;
    }
  }
}


.btnBox {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  .btn {
    @include primary-btn;
  }
}


.extra {
  @include heading(14px, 300);
  margin-bottom: 16px;

  .btn {
    color: $primary-color;
    display: inline-block;
    cursor: pointer;

    .txt {
      text-decoration: underline;
      margin: 0 5px;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal_title {
    @include heading(14px, 500);
  }

  .modal_body {
    margin: 16px 0;
    width: 100%;
    white-space: pre-line;
  }
  .modal_btn {
    @include primary-btn;
  }
}