@use "src/utils" as *;
@use "src/global" as *;

.box {
  background-color: $background-color;
  border-radius: 2px;
  padding: 20px;

  .title {
    @include heading(14px, 500);
    margin-bottom: 10px;
  }

  .content {
    @extend %context;
    margin-bottom: 30px;
    white-space: pre-wrap;
  }
}

.bold_txt {
  @include heading(14px, 500);
  margin-bottom: 30px;
}