@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;

  .box {
    @include box(unset);
    padding-top: $headerGap;
  }
}

.row {
  display: flex;
  align-items: center
}

.searchBox {
  @extend %context;
  width: 350px;
  display: flex;
  margin-bottom: 30px;

  @include sm {
    width: 100%;
  }

  .search {
    @extend %context;
  }

  .searchBtn {
    @include primary-btn;
    height: 42px;
    width: 80px;
    border-radius: 0px 2px 2px 0;
  }

}

.floatBtn {
  @include primary-btn;
  left: 100%;
  bottom: 50px;
  position: sticky;
  margin-top: 30px;
  @include sm {
    width: 100%;
  }
}



.card_box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  column-gap: 16px;
  row-gap: 50px;
}

.card_box_two_col {
  @include sm {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  }
}

.card_content {
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #A8A29E;
}

.card_content_tag {
  color: $tag-color-red;
  border: 1px solid $tag-color-red;
  border-radius: 2px;
  font-family: Noto Sans TC;
  font-weight: 400;
  font-size: 10px;
  padding: 0 4px;
  margin-left: 8px;
}

.time {
  color: $primary-color;
}

.endedFooter {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;

  .primary {
    @include primary-btn;
    width: 80px;
  }
  .secondary {
    @include secondary-btn;
    width: 80px;
  }
}

.dangerColor {
  color: $danger-color;
}

.noCatBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  .noCat {
    font-family: Noto Sans TC;
    font-size: 28px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    color: $heading-color;
  }
}
