@use "src/utils" as *;
@use "src/global" as *;

$bg_color_type_0: rgb(255, 255, 255);

$color_type_0: rgb(168, 162, 158);
$color_type_1: rgb(63, 63, 70);

$font_type_0: 400 14px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 400 12px/1.83 "Noto Sans TC", Helvetica, Arial, serif;

.card_content {
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 300;
  line-height: 22.4px;
  color: #A8A29E;
}

.info-section {
  @include main;
  background-color: $bg_color_type_0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.info-content {
  align-items: flex-start;
  display: flex;
  gap: 0px 48px;
  margin: 30px auto 42.5px;
  max-width: 1440px;
  position: relative;
  width: 100%;
  @include md {
    align-items: stretch;
    aspect-ratio: auto;
    flex-direction: column;
    gap: 24px 0px;
    margin: 30px 24px 42.5px;
    width: auto;
  }
  @include sm {
    gap: 48px 0px;
  }
  @include xxs {
    gap: 24px 0px;
    margin: 30px 16px 42.5px;
  }
  @include tn {
    gap: 20px 0px;
    margin: 20px 16px;
  }
}
.panel-left {
  display: flex;
  flex-direction: column;
  gap: 13px 0px;
  min-width: 0px;
  position: relative;
  width: 766px;
  @include md {
    aspect-ratio: auto;
    flex: 0 1 auto;
    order: 3;
    width: auto;
  }
}
.header {
  display: flex;
  flex-direction: column;
  margin: 0px 4px 0px 0px;
}
.profile-grid-container {
  align-content: start;
  column-gap: 21px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  row-gap: 12px;
  @include mq-between(xxxl, max) {
    justify-content: start;
  }
  @include xxxl {
    justify-content: start;
  }
  @include xxl {
    grid-template-columns: repeat(3, 1fr);
  }
  @include lg {
    grid-template-columns: repeat(2, 1fr);
  }
  @include tn {
    column-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }
}
.profile-item {
  grid-column: span 1;
  grid-row: span 1;
  min-width: 0;
  position: relative;
}
.panel-right {
  display: flex;
  flex-direction: column;
  gap: 16px 0px;
  min-width: 0px;
  position: relative;
  width: 626px;
  @include md {
    aspect-ratio: auto;
    flex: 0 1 auto;
    order: 1;
    width: auto;
  }
}
.header1 {
  display: flex;
  flex-direction: column;
}
.content-container {
  align-content: start;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0px 16px;
  position: relative;
  row-gap: 20px;
  @include mq-between(xxxl, max) {
    column-gap: 0px;
    justify-content: start;
  }
  @include xxxl {
    column-gap: 0px;
    justify-content: start;
  }
}
.content-item {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 1;
}
.card_ongoing_count {
  font-size: 12px;
  font-weight: 500;
  line-height: 19.2px;
  color: #00C2D6;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 19px;
  color: #44403C;
  overflow: hidden;

  .name {
    transform: translateY(-1px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contract_flag {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: 1px solid #A8A29E;
    border-radius: 2px;
    padding: 4px 8px;
  }
}