@use "src/utils" as *;
@use "src/global" as *;

.page {
width: 100vw;
height: 100vh;
display: flex;
flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans TC;
  font-size: 28px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: 1px;
  text-align: center;
  color: $heading-color;
}

.col {
  display: flex;
  flex-direction: column;
}

.btn {
  @include primary-btn;
  margin-top: 20px;
}