// color
$primary-color: #00A5B8;
$danger-color: #B91C1C;
$heading-color: #3F3F46;
$subheading-color: #A8A29E;
$special-color: #CC3300;
$positive-comment-color: #0891B2;
$negative-comment-color: #F97316;
$unselect-color: #D4D4D8;
$tag-color-red: #BE185D;


// background color
$background-color: #F5F5F5;
$border-color: #E7E5E4;


// font Stack
$fallback-font-stack: Helvetica, Arial, serif;

$headerGap: 30px;


%context {
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: $heading-color;
}

%context-light {
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 1px;
  text-align: left;
  color: $heading-color;
}

%cat-name {
  font-family: Noto Sans TC;
  font-size: 28px;
  font-weight: 300;
  line-height: 45px;
  letter-spacing: 1px;
  text-align: center;
  color: $heading-color;
}


@mixin heading($fontSize: 14px, $fontWeight: 300) {
  font-family: Noto Sans TC;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: 22.5px;
  letter-spacing: 0em;
  text-align: left;
  color: $heading-color;
}

@mixin main {
  padding: 0 16px;
}

@mixin box($minHeight: 600px) {
  background-color: rgb(255, 255, 255);
  position: relative;
  max-width: 1440px;
  width: 100%;
  min-height: $minHeight;
  margin: 0 auto;
  box-sizing: border-box;
}

@mixin primary-btn {
  background-color: $primary-color;
  padding: 8px;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  border: none;
  height: 40px;
  width: 160px;

  &:hover {
    color: #FFFFFF !important;
    background-color: darken($primary-color, 5%) !important;
  }
}


@mixin secondary-btn {
  background-color: $subheading-color;
  padding: 8px;
  border-radius: 2px;
  color: #FFFFFF;
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  border: none;
  height: 40px;
  width: 160px;

  &:hover {
    color: #FFFFFF !important;
    background-color: darken($subheading-color, 5%) !important;
  }
}