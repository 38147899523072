@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
}

.card_box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  column-gap: 16px;
  row-gap: 12px;

  @include sm {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  }
}

.card_content {
  @include heading;
  font-size: 12px;
  font-weight: 300;
  line-height: 22.4px;
  color: #A8A29E;
}

.card_ongoing_count {
  font-size: 12px;
  font-weight: 500;
  line-height: 19.2px;
  color: #00C2D6;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 19px;
  color: #44403C;
  overflow: hidden;

  .name {
    transform: translateY(-1px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contract_flag {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: 1px solid #A8A29E;
    border-radius: 2px;
    padding: 4px 8px;
  }
}

.filter_outerBox_pc {
  margin-bottom: 30px;

  @include sm {
    display: none;
  }
}

.filter_outerBox_mobile {
  display: none;

  @include sm {
    display: block;
    border: 1px solid $border-color;
    padding: 16px;
    margin-bottom: 30px;
  }
}

.filter_innerBox {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;

  @include sm {
    flex-direction: column;
    row-gap: 15px;
    justify-content: flex-end;
  }
}

.filter_item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  @include sm {
    width: 100%;
  }

  @include xxs {
    flex-direction: column;
    align-items: flex-start;
  }

  .title {
    font-size: 14px;
    font-weight: 300;
    color: #000000D9;
    white-space: nowrap;

      @include sm {
        width: 70px;
        text-align: right;
      }

      @include xxs {
        text-align: left;
      }
    }

  .input {
    @include sm {
      flex: 1;
      width: 100%;
    }

  }

  .keywordInput {
    min-width: 230px;

    @include sm {
      flex: 1;
      min-width: unset;
    }
  }
}


.btn {
  background-color: $primary-color;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 1px;
  border: none;
  height: 40px;
  width: 160px;
  border-radius: 2px;

  &:hover {
    background-color: darken($primary-color, 5%);
  }

  @include sm {
    align-self: self-end;
  }

  @include xxs {
    width: 100%;
  }
}

.btn:hover {
  color: #FFFFFF !important;
}

.noCatBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  .noCat {
    font-family: Noto Sans TC;
    font-size: 28px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    color: $heading-color;
  }
}