@use "src/utils" as *;
@use "src/global" as *;

.tab {
  position: relative;
  padding: 0 15px;
  margin-bottom: 30px;
  max-width: 100%;

  @include sm {
    padding: 0 10px;
  }

  .line {
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid $border-color;
  }

  .box {
    display: flex;
    column-gap: 10px;
    width: 100%;;
    @include sm {
      column-gap: 5px;
    }

    .item {
      height: 40px;
      flex: 0 1 200px;
      color: #3F3F46;
      background-color: #F5F5F5;
      border: none;
      border-bottom: 1px solid $border-color;
      font-weight: 400;

      @include tn {
        padding: 0 10px;
      }
    }

    .active {
      background-color: #FFFFFF;
      border: 1px solid $border-color;
      border-bottom: 2px solid #FFFFFF;
    }
  }

}

