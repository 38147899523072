@use "src/utils" as *;
@use "src/global" as *;

$color_type_0: rgb(168, 162, 158);
$color_type_1: rgb(63, 63, 70);

$bg_color_type_0: rgb(231, 229, 228);
$bg_color_type_1: rgb(255, 255, 255);

$font_type_0: 400 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 300 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_2: 300 12px/1.6 "Noto Sans TC", Helvetica, Arial, serif;

.root {
  background-color: $bg_color_type_1;
  border-color: rgb(231, 229, 228);
  border-style: solid;
  border-width: 4px 1px 1px 1px;
  display: flex;
  flex-direction: column;
  // min-height: 364px;
  position: relative;
  height: 100%;
}
.flex_col {
  font: $font_type_1;
  display: flex;
  flex-direction: column;
  margin: 13px;
  position: relative;
  flex: 1;
}
.flex_col1 {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}
.image {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #D4D4D4;
}
.text {
  align-items: center;
  color: $color_type_1;
  display: flex;
  font: $font_type_0;
  line-height: 22.4px;
  font-weight: 400;
  letter-spacing: 0px;
  margin: 12px 0px 3px 0px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.group {
  position: relative;
}
.line {
  margin: 12px 0;
  height: 1px;
  border-bottom: 1px solid $bg_color_type_0;
}
// .footer {
//   display: flex;
//   gap: 0px 16px;
//   justify-content: center;
//   margin: 8px 0px 0px;
//   position: relative;
// }
// .text1 {
//   color: $color_type_0;
//   font: $font_type_1;
//   letter-spacing: 0px;
//   margin: 3px 0px 2px;
//   min-height: 23px;
//   width: 100%;
// }
// .box {
//   min-height: 28px;
//   position: relative;
//   width: 75px;
// }
.image1 {
  height: 3px;
  object-fit: cover;
  position: absolute;
  right: 44px;
  top: -3px;
  width: 6px;
}
.image2 {
  height: 6px;
  object-fit: cover;
  position: absolute;
  right: -3px;
  top: 45px;
  width: 3px;
}
.icon {
  height: 51px;
  object-fit: cover;
  position: absolute;
  right: -3px;
  top: -3px;
  width: 50px;
}
.icon1 {
  height: 39px;
  object-fit: cover;
  position: absolute;
  right: -3px;
  top: -3px;
  width: 39px;
}

.outmostWrapper {
  position: relative;
  height: 100%;
}
