@use "src/utils" as *;
@use "src/global" as *;

.box {
  background-color: $background-color;
  border-radius: 2px;
  padding: 20px;

  .title {
    @include heading(14px, 500);
  }
  
  .content {
    @extend %context;
    white-space: pre-wrap;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .mb30 {
    margin-bottom: 30px;
  }
}

.boldTxt {
  @include heading(14px, 500);
}
