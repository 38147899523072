@use "src/utils" as *;
@use "src/global" as *;

.main {
  background-color: $background-color;
}

.box {
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: min(535px, 90vw);
  margin: 100px 0;
  padding: 30px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: Noto Sans TC;
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 0px;
  margin: 0;
  text-align: center;
}

.row {
  @extend %context;
  width: 100%;
  margin-top: 30px;
}

.field {
  padding: 0 16px;
  margin-bottom: 5px;
}

.primaryBtn {
  @include primary-btn;
  width: 100%;
  margin-top: 30px;
}

.secondaryBrn {
  @extend %context-light;
  background-color: #FFF;
  padding: 8px;
  border-radius: 2px;
  text-align: center;
  height: 40px;
  width: 100%;
  border: 1px solid $border-color;
  margin-top: 10px;
}

.text {
  @extend %context-light;
}

.link {
  @extend %context-light;
  color: $primary-color;
  text-decoration: underline;
}

.alignRight {
  margin-top: 10px;
  align-self: flex-end;
}
