@use 'src/utils' as *;
@use 'src/global' as *;

.main {
  background-color: #f5f5f5;
  color: #3f3f46;
  padding: 16px 20px;

  .top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px;

    .person {
      @include heading(18px, 400);
      display: flex;
      column-gap: 10px;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 32px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    padding: 0 16px 16px 16px;
    gap: 0 8px;

    @include xs {
      gap: 0 12px;
    }

    .button {
      @include primary-btn;

      width: 120px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .message {
      .icon {
        fill: #fff;
        height: 16px;
        width: 16px;
      }

      @include xs {
        flex: 1;
      }
    }

    .comment {

      @include xs {
        flex: 1;
      }
    }
  }

  .center {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 50px;
    padding: 16px;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .key {
      @include heading(14px, 500);
      text-align: right;
    }
    .value {
      @include heading(14px, 300);
      text-align: left;
    }
    .icon {
      color: #a8a29e !important;
      margin: 0 5px;
    }
  }

  .bottom {
    @include heading;
    padding: 16px;
  }
}
