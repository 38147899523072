@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  max-width: 860px;
  margin-top: $headerGap;
}

.avatarBox {
  @include heading(18px, 400);
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 8px 0px;
}

.disclaimer {
  @include heading(14px, 500);
  color: $subheading-color;
  margin-bottom: 16px;
}


.noCommentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  .noComment {
    font-family: Noto Sans TC;
    font-size: 28px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    color: $heading-color;
  }
}