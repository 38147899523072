@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
}

.card_box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  column-gap: 30px;
  row-gap: 50px;

  @include sm {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.card_content {
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  color: $heading-color;
  height: 66px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hide {
  display: none;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .title {
    color: $subheading-color;
  }
}

.date {
  @extend %context;
  color: $subheading-color;
}

.heading {
  @include heading(14px, 500);
  color: $subheading-color;
}


.tag {
  padding: 8px 12px;
  border-radius: 2px;
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
}
.tagNews {
  background-color: $subheading-color;
  color: #FFFFFF;
}
.tagEventOngoing {
  background-color: $primary-color;
  color: #FFFFFF;
}
.tagEventNotStart {
  border: 1px solid $primary-color;
  color: $primary-color;
}
.tagEventEnded {
  border: 1px solid $subheading-color;
  color: $subheading-color;
}
