@use "src/utils" as *;
@use "src/global" as *;

$color_type_0: rgb(168, 162, 158);
$color_type_1: rgb(255, 255, 255);
$color_type_2: rgb(51, 51, 51);

$bg_color_type_0: rgb(147, 18, 32);
$bg_color_type_1: rgb(255, 255, 255);

$padding_type_0: 17px 0px 12px 0px;
$padding_type_1: 0px 16px 0px 16px;

$border_radius_type_0: 2px 0px 0px 0px;
$border_radius_type_1: 2px 2px 2px 2px;

$font_type_0: 900 20px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 400 12px/1.39 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_2: 300 12px/1 "Noto Sans TC", Helvetica, Arial, serif;

.header {
  flex-shrink: 0;
}

.avatarDropdown {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: $color_type_2;
  text-decoration: none;
  overflow: hidden;
  padding: 3px 0 3px 3px;
  cursor: pointer;

  &:hover {
    .avatar {
      outline: 3px solid $primary-color;
      border-radius: 50%;
    }
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
  }

  .name {
    line-height: 1.4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .unread {
    position: absolute;
    top: 3px;
    left: 29px;
    background-color: #FF4D4F;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #fff;
  }
}

.box {
  background-color: $bg_color_type_1;
  border-color: #E0E0E0;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: $padding_type_1;
  position: relative;
}
.unnamed {
  display: flex;
  flex-direction: column;
  gap: 12px 0px;
  margin: 12px auto 0px;
  max-width: 1440px;
  position: relative;
  width: 100%;
  @include sm {
    aspect-ratio: auto;
    margin: 0px auto;
    gap: 17px 0px;
  }
}
.unnamed1 {
  align-items: center;
  display: flex;
  gap: 0px 8px;
  position: relative;
  @include sm {
    aspect-ratio: auto;
    margin: 16px 0px;
  }
}
.image {
  display: none;
  height: 14px;
  margin: 13px 0px 16px;
  min-width: 0px;
  object-fit: cover;
  width: 18px;
  @include sm {
    aspect-ratio: auto;
    display: flex;
  }
}
.logo_link {
  margin-right: auto;

  @include sm {
    display: flex;
    align-items: center;
  }

  .logo {
    height: 60px;
    margin: 0 auto;

    @include sm {
      height: 42px;
      padding: 0 10px;
    }
  }
}
.subtitle {
  color: $color_type_2;
  font: $font_type_0;
  letter-spacing: 0px;
  min-width: 0px;
  width: 49px;
}
.box1 {
  background-color: $bg_color_type_0;
  border-radius: $border_radius_type_1;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 3px;
  min-width: 0px;
  position: relative;
  width: 160px;
  text-decoration: none;
}
.info {
  align-items: center;
  color: $color_type_1;
  display: flex;
  font: $font_type_2;
  justify-content: center;
  letter-spacing: 0.8px;
  margin: 14px 38px 14px 39px;
  text-align: center;
}
.navRow {
  display: flex;
  position: relative;

  > a {
    border-color: $border-color;
    border-style: solid;
  }

  > a:not(:first-child, :last-child) {
    border-width: 1px 0px 0px 1px;
  }

  > a:first-child {
    border-width: 1px 0px 0px 1px;
  }

  > a:last-child {
    border-width: 1px 1px 0px 1px;
  }

  @include sm {
    aspect-ratio: auto;
    display: none;
  }
}
.home {
  border-radius: $border_radius_type_0;
  display: flex;
  flex-direction: column;
  gap: 4px 0px;
  min-width: 0px;
  padding: $padding_type_0;
  position: relative;
  width: 100px;
  text-decoration: none;
}
.image1 {
  height: 20px;
  margin: 0px auto;
  object-fit: cover;
  width: 18px;
}
.info1 {
  align-items: center;
  color: $color_type_0;
  display: flex;
  font: $font_type_1;
  justify-content: center;
  letter-spacing: 0px;
  text-align: center;
}
.content_box {
  flex: 1;
  min-width: 0px;
  position: relative;
  text-decoration: none;
}
.active {
  height: 4px;
  background-color: $primary-color;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.activeSubtitle {
  color: #00C2D6;
}
.activeTitle {
  color: $primary-color;
}

.overlay {
  min-width: 106px !important;
}

.items {
  padding: 4px 0;

  .item {
    display: flex;
    flex-direction: row;
    padding: 5px 12px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .unread {
    background-color: #CC3300;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 3px 0 0 4px;
  }

  .label {
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.headerBottom {
  display: flex;
  align-items: center;
  gap: 10px;

  .externalLink {
    color: $primary-color;
    font-size: 14px;
    margin-top: 2px;

    @include sm {
      display: none;
    }
  }
}
