@use "src/utils" as *;
@use "src/global" as *;

.main {
  @extend %context;
  background-color: $background-color;
  padding: 20px;
  margin-top: 20px;

  .title {
    @include heading(14px, 500);
    margin-bottom: 10px;
  }

  .subtitle {
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
  }
}

.divider {
  border-bottom: 1px solid $border-color;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .btn {
    @include primary-btn;
  }

}

.blue {
  color: $positive-comment-color;
}

.orange {
  color: $negative-comment-color;
}

.unSelect {
  color: $unselect-color;
}