@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include  box;
  margin-top: $headerGap;
  display: flex;
  row-gap: 30px;
  column-gap: 10px;

  @include md {
    flex-direction: column;
  }

  .leftCol {
    flex: 0 1 480px;

    @include md {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include xxs {
      flex-direction: column;
    }

    .imgBox {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px;

      @include md {
        width: 180px;
        margin-bottom: 0;
        order: 1;
      }
      @include xxs {
        width: 100%;
      }

      .img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .completeness {
      width: 100%;
      margin: 30px 0;

      @include md {
        width: 100%;
        margin: 30px 0 0;
        order: 3;
      }
    }

    .meta {
      @extend %context;
      color: $subheading-color;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include md {
        width: calc(100% - 180px);
        order: 2;
        align-items: flex-start;
        padding-left: 16px;
      }
      @include xxs {
        width: 100%;
        padding-left: 0px;
      }

      .name {
        @extend %cat-name;
      }

      .btn {
        @include primary-btn;
        margin-top: 50px;

        @include md {
          margin-top: 10px;
        }
      }
    }
  }

  .rightCol {
    flex: 1;
  }
}

.primary {
  color: $primary-color;
}

.error {
  width: 100px;
  height: 100px;
  outline: 1px solid $special-color;
}

.explain {
  @include heading;
  padding: 0 16px 16px;

  div {
    display: flex;

    span:first-child {
      margin-right: 5px;
    }
  }
}

@keyframes antZoomIn {
  from {
    transform: translateY(-5px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}

.showError {
  color: $special-color;
  opacity: 1;
  animation: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) antZoomIn;
}
.hideError {
  opacity: 0;
  animation: 0.2s	cubic-bezier(0.215, 0.61, 0.355, 1) antZoomOut;
}


.infoTab {
  @extend %context;
  position: relative;
  padding: 0 16px 16px;


  .subheading {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    .title {
      flex: 1;
      @include heading(18px, 400);
    }
    .percentage{
      margin: 0 3px;
      font-family: Noto Sans TC;
      font-size: 32px;
      font-weight: 700;
      line-height: 51px;
    }
  }


  .saveBtn {
    @include primary-btn;
    left: 100%;
    bottom: 50px;
    position: sticky;
    @include sm {
      width: 100%;
    }
  }
}

.contractTab {
  padding: 0 16px 16px;

  .header {
    @include heading(14px, 500);
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .icon {
      margin-right: 20px;
      font-size: 20px;
      color: $heading-color;
    }
  }

  .explain {
    padding: 0 0 16px;
  }

  .contentBox {
    background-color: $background-color;
    border-radius: 2px;
    padding: 20px;

    .title {
      @include heading(14px, 500);
      margin-bottom: 10px;
    }

    .content {
      @extend %context;
      margin-bottom: 30px;
    }
  }

  .version {
    margin-top: 5px;
    text-align: right;
    font-size: 12px;
    font-weight: 300;
  }
}

.applicationTab {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  padding: 0 16px 16px;

  @include tn {
    grid-template-columns: repeat(auto-fill, 100%);
  }

  .applicationBox {
    border: 1px solid $border-color;
    padding: 16px;
    text-decoration: none;

    .redDot {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 12px;
      height: 12px;
      background-color: $tag-color-red;
      border-radius: 50%;
    }

    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      @include tn {
        flex-wrap: wrap;
        row-gap: 10px
      }

      .name {
        @include heading(18px, 400);
        @include tn {
          font-size: 16px;
        }
      }

      .tag {
        @extend %context-light;
        position: relative;
        padding: 8px 12px;
        border: 1px solid $primary-color;;
        color: $primary-color;
        border-radius: 2px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        @include tn {
          padding: 4px 8px;
        }
      }

      .tagCancel {
        border: 1px solid $subheading-color;
        color: $subheading-color;
      }
    }

    .date {
      @extend %context-light;
      margin-bottom: 13px;
      color: $subheading-color;

      @include tn {
        margin-bottom: 6px;
      }
    }
  }

}


.noCats {
  @include heading(14px, 500);
}