@use "src/utils" as *;
@use "src/global" as *;

.reaction {
  display: flex;
  align-items: center;
  margin: 9px 0 12px 0;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
  }

  .icon {
    width: 28px;
    height: 28px;
    z-index: 1;
  }

  .label {
    margin: 0 8px;
    color: $primary-color;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
  }

  .active {
    fill: $primary-color;
  }

  .share {
    width: 28px !important;
    height: 28px;
    padding: 0;
  }

  .share:hover {
    background-color: transparent !important;
  }

  .shareIcon {
    width: 28px;
    height: 28px;
    fill: $primary-color;
  }
}
