.pawWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #D4D4D4;
}

.paw {
  width: 40%;
}