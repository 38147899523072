@use "src/utils" as *;
@use "src/global" as *;

.card {
  position: relative;
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(204, 51, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 19.2px;
  }

  .spin {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .opacity20 {
    opacity: 0.2;
  }

  .button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4px;
    right: 4px;
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 4px;
    border-color: $border-color;

    .x {
      fill: $subheading-color;
    }

    &:active {
      border: none;
    }
  }
}

.pointer {
  cursor: pointer;
}

.preview {
  width: 100%;
}
