@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box(unset);
  margin-top: $headerGap;
  max-width: 880px;
  @extend %context;
}

.formGap {
  margin-bottom: 24px;
}

.fieldGap {
  padding-bottom: 8px;
}

.extra {
  @extend %context-light;
  color: $special-color;
}

.bold {
  font-weight: 700;
}

.row {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @include sm {
    flex-direction: column
  }

  .primary {
    @include primary-btn;
    @include sm {
      width: 100%;
    }
  }

  .secondary {
    @include secondary-btn;
    @include sm {
      width: 100%;
    }
  }
}
