@use "src/utils" as *;
@use "src/global" as *;

.main {
  width: 100%;
  height: 100%;

  .header {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    padding: 10px;
  }

  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :global(.ant-pagination) {
    align-items: center;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spacer {
  height: 10px;
}

.formTitle {
  padding: 12px 5px;
}

.redTxt {
  color: #ff4d4f;
}

.extra {
  padding: 5px;
  margin-top: -10px;
  margin-bottom: 10px;
}