@use "src/utils" as *;
@use "src/global" as *;

.item {
  display: flex;
  width: 100%;
  font-weight: 300;
  line-height: 22px;
  color: $heading-color;
  border-bottom: 1px solid $border-color;
  padding: 2px 0 3px;
  flex: 1;
  white-space: pre-line;
  box-sizing: border-box;


  .key {
    padding: 16px;
    background-color: $background-color;
    min-width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include tn {
      min-width: 100px;
    }

  }

  .value {
    padding: 16px 20px;
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.header {
  @include main;
  background-color: $background-color;


  .head_box {
    @include box(unset);
    background-color: $background-color;
    padding-top: 10px;
    padding-bottom: 32px;

    .sticker {
      width: 100%;
      height: 80px;
      max-width: 380px;
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      background-color: #FFF;
      box-shadow: 0px 10px 18px 0px #091E4226;
      align-items: center;
      font-size: 18px;
      line-height: 29px;
      letter-spacing: 0px;
      text-decoration: none;
      color: $heading-color;
      box-sizing: border-box;

      @include sm {
        max-width: unset;
        height: unset;
      }

      .contentUser {
        flex: 1;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .title {
          margin-right: 30px;
        }
      }

      .light {
        color: #A8A29E;
      }

      .primary {
        color: #00A5B8;
        font-weight: 700;
        font-size: 32px;
      }

    }
  }
}

.main {
  @include main;

  .box {
    @include box(unset);
    padding-top: $headerGap;


    .tickets {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    .events {
      @extend  %context;

      .row {
        display: flex;
        align-items: center;
        padding: 16px 20px;
        row-gap: 16px;
        column-gap: 32px;

        @include sm {
          flex-direction: column;
          justify-content: space-between;
        }
      }

      .divider {
        margin: 2px 0;;
        border-bottom: 1px solid $border-color;
      }


      .partOne {
        display: flex;

        @include sm {
          width: 100%;
          justify-content: space-between;
          column-gap: 16px;
        }

        .sapn {
          margin-left: 10px;
          margin-right: 15px;
          @include heading(14px, 500);
          @include sm {
            margin-right: 0px;
          }
        }
      }


      .partTwo {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 16px;
        @include heading(14px, 500);

        .tag {
          @extend  %context-light;
          width: 60px;
          min-width: 60px;
          height: 28px;
          border: 1px solid $border-color;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFF;
          border-radius: 2px;
        }

        .blueTag {
          color: #FFF;
          background-color: $primary-color;
        }
      }
    }
  }
}

.hide {
  display: none;
}