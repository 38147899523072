@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include  box;
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;

  @include sm {
    flex-direction: column;
  }
}


.leftCol {
  flex: 0 1 480px;

  @include md {
    flex: 1;
  }

  .updateAt {
    @extend %context-light;
    height: 30px;
  }

  .name {
    font-size: 32px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: left;
    color: #3F3F46;
    margin: 0 0 25px 0;
  }
}

.rightCol {
  flex: 1;

  .btnBox {
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-top: 30px;

    @include sm {
      justify-content: center;
      margin-top: 0px;

      > a:first-child {
        width: 100%;
      }
    }

    .btn1 {
      @include secondary-btn;

      @include sm {
        width: 100%;
      }
    }

    .btn2 {
      @include primary-btn;

      @include sm {
        width: 100%;
      }
    }

    .inContractBtn {
      flex-shrink: 0;
      cursor: not-allowed;
      color: #A8A29E;
      border: 1px solid #A8A29E;
      border-radius: 2px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      padding: 14px;
    }
  }


  .collapse {
    border: 1px solid $border-color;
    border-top: 4px solid $border-color;
    margin-bottom: 30px;
  }

  .label {
    color: #3F3F46;
    font-size: 18px;
  }

  .extra {
    font-weight: 500;
    letter-spacing: 0em;
    text-align: right;
    min-width: 85px;
    letter-spacing: 1px;
  }
}

.tag {
  color: #A8A29E;
  outline: 1px solid #A8A29E;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 1px;
  padding: 4px;
  margin: 0 5px;
  white-space: nowrap;
}
.tagValue {
  margin-left: 10px;
  white-space: nowrap;
}

.report {
  display: flex;
  column-gap: 25px;
  row-gap: 10px;
  flex-wrap: wrap;
}
