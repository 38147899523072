@use "src/utils" as *;
@use "src/global" as *;

.card {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 0 0 15px 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: $border-color;

  @include xl {
    padding: 0 20px 15px 8px;
  }

  .user {
    display: flex;
    align-items: center;
    gap: 16px;

    .avatarWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 20px;
      background-color: #BFBFBF;
    }

    .avatar {
      width: 100%;
      height: 100%;
    }

    .defaultAvatar {
      width: 20px;
      height: 20px;
    }

    .name {
      font-size: 14px;
      font-weight: 300;
      line-height: 22.4px;
    }
  }

  .content {
    margin: 8px 0;

    .text {
      font-size: 14px;
      font-weight: 500;
      line-height: 22.4px;
      margin: 0 50px;
      padding: 0 0 4px 0;
      word-wrap: break-word;
    }

    .foster {
      margin-left: 50px;
      padding: 4px 0;
    }

    .files {
      margin-left: 50px;
      padding: 4px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .images {
      margin-left: 50px;
      padding: 4px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .timestamp {
      color: $subheading-color;
      font-size: 14px;
      font-weight: 300;
      line-height: 22.4px;
      margin-left: 45px;
    }
  }
}

.last {
  border: none;
}
