@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include  box;
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;

  @include sm {
    flex-direction: column;
  }

  .leftCol {
    flex: 0 1 480px;

    @include md {
      flex: 1;
    }

    .title {
      display: none;

      @include sm {
        display: block;
      }
    }
  }

  .rightCol {
    flex: 1;

    .title {
      padding: 0 10px;

      @include sm {
        display: none;
      }
    }
  }
}

.title {
  @include heading(18px, 400);
  margin: 0 0 16px 0;
}
