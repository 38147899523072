.img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 1;
  background-color: #D4D4D4;
  margin-bottom: -3px;
}

.spacer {
  height: 16px;
}

.row {
  width: 100%;
  display: flex;
  column-gap: 10px;
}

.paw {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.thumbnail {
  width: 100%;
  aspect-ratio: 1 / 1;
}

.selected {
  box-sizing: border-box;
  outline: 4px solid #00C2D6;
}