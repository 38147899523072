@use "src/utils" as *;
@use "src/global" as *;

$color_type_0: rgb(168, 162, 158);
$color_type_1: rgb(63, 63, 70);

$font_type_0: 400 14px/1.5 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 400 12px/1.83 "Noto Sans TC", Helvetica, Arial, serif;

.infoItem {
  display: flex;
  flex-direction: column;
  gap: 4px 0px;
  position: relative;
}

.message {
  color: $color_type_1;
  font: $font_type_0;
  letter-spacing: 0px;
}

.date {
  color: $color_type_0;
  font: $font_type_1;
  letter-spacing: 0px;
}
