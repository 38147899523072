@use "src/utils" as *;
@use "src/global" as *;

.main {
  width: 100%;
  height: 100%;

  .header {
    padding: 10px;
  }

  .titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }

}

.spacer {
  height: 10px;
}

.formTitle {
  padding: 12px 5px;

}

.redTxt {
  color: #ff4d4f;
}

.tabContainer {
  height: calc(100vh - 170px);
  overflow-y: scroll;
}

.extra {
  padding: 5px;
  margin-top: -10px;
  margin-bottom: 10px;
}