@use "src/utils" as *;
@use "src/global" as *;

$color_type_0: rgb(168, 162, 158);
$color_type_1: rgb(63, 63, 70);

$bg_color_type_0: rgb(245, 245, 245);
$bg_color_type_1: rgb(255, 255, 255);

$padding_type_0: 10.5px 16px 12px 16px;

$font_type_0: 700 16px/1.39 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 400 12px/1.39 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_2: 300 12px/1.39 "Noto Sans TC", Helvetica, Arial, serif;

.root {
  background-color: $bg_color_type_0;
  border-color: rgb(2, 132, 199);
  border-style: solid;
  border-width: 0px 0px 0px 3px;
  display: flex;
  gap: 0px 8px;
  justify-content: space-between;
  padding: $padding_type_0;
  position: relative;
}
.left-parent {
  display: flex;
  flex-direction: column;
  gap: 2px 0px;
  min-width: 0px;
  position: relative;
}
.title {
  align-items: center;
  color: $color_type_1;
  display: flex;
  font: $font_type_0;
  letter-spacing: 0px;
  margin: 0;
}
.info {
  align-items: center;
  color: $color_type_0;
  display: flex;
  font: $font_type_1;
  letter-spacing: 0px;
  margin: 0px 3px 0px 0px;
}
.more-button {
  background-color: $bg_color_type_1;
  display: flex;
  flex-direction: column;
  margin: 9.5px 0px 8px;
  min-width: 0px;
  outline: 1px solid rgb(231, 229, 228);
  outline-offset: -1px;
  position: relative;
  width: 73px;
}
.button-text {
  align-items: center;
  color: $color_type_1;
  display: flex;
  font: $font_type_2;
  letter-spacing: 0px;
  padding: 4px 20px;
  text-decoration: none;
}
