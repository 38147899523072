@use "src/utils" as *;
@use "src/global" as *;

$bg_color_type_0: rgb(255, 255, 255);
$bg_color_type_1: rgb(63, 63, 70);
$color_type_0: rgb(255, 255, 255);
$padding_type_0: 16px;
$font_type_0: 400 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_1: 300 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;

// Search
.search_wrapper {
  @include main;
}

.search {
  @include box(unset);
  margin-top: $headerGap;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  margin-bottom: 14px;

  .search_item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @include sm {
      width: 100%;
    }

    .search_item_title {
      font-size: 14px;
      font-weight: 300;
      color: #000000D9;
      white-space: nowrap;
      margin-right: 5px;

      @include sm {
        margin: 0;
        padding-right: 5px;
        min-width: 65px;
      }
    }

    .search_item_input {
      min-width: 200px;

      @include sm {
        flex: 1;
        width: 100%;
      }
    }
  }

  .search_btn {
    background-color: $primary-color;
    color: #FFFFFF;
    border: none;
    font-weight: 400;
    letter-spacing: 1px;
    height: 40px;
    width: 160px;
    border-radius: 2px;

    &:hover {
      background-color: darken($primary-color, 5%) !important;
    }

    @include sm {
      width: 100%;
    }
  }
}

// GridCard
.imgCard {
  background: var(--src) center center / cover no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 348px;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.caption {
  background-color: $bg_color_type_1;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  height: 71px;
  left: 0px;
  opacity: 0.8;
  position: absolute;
  right: 0px;

  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.textBox {
  display: flex;
  flex-direction: column;
  gap: 4px 0px;
  margin: 8px 16px 7px;
  position: relative;
}
.title {
  margin: 0;
  color: $color_type_0;
  font: $font_type_0;
  letter-spacing: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content {
  color: $color_type_0;
  font: $font_type_1;
  letter-spacing: 0px;
}
// GridBox
.root {
  background-color: $bg_color_type_0;
  display: flex;
  flex-direction: column;
  min-height: 1584px;
  overflow: hidden;
  padding: $padding_type_0;
  position: relative;
}
.gridBox {
  align-content: start;
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0px auto 16px;
  max-width: 1440px;
  // min-height: 1440px;  有待觀察
  position: relative;
  row-gap: 16px;
  width: 100%;
  @include mq-between(xxxl, max) {
    justify-content: start;
  }
  @include xxxl {
    justify-content: start;
  }
  @include md {
    grid-template-columns: repeat(3, 1fr);
  }
  @include sm {
    grid-template-columns: repeat(2, 1fr);
  }
}
.component0 {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  grid-row: span 2;
  @include md {
    order: 1;
  }
}
.component1 {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 2;
  @include md {
    order: 2;
  }
  @include xs {
    aspect-ratio: auto;
    min-height: 348px;
  }
}
.component2 {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 1;
  @include md {
    order: 3;
  }
}
.component3 {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 1;
  @include md {
    order: 5;
  }
}
.component4 {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 2;
  @include md {
    order: 4;
  }
  @include sm {
    grid-row: span 1;
  }
}
.component5 {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 1;
  @include md {
    order: 6;
  }
  @include sm {
    grid-row: span 2;
  }
}
.component6 {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  grid-row: span 1;
  @include md {
    grid-column: span 1;
    order: 7;
  }
}
.component7 {
  display: flex;
  flex-direction: column;
  grid-column: span 2;
  grid-row: span 1;
  @include md {
    order: 8;
  }
  @include sm {
    grid-column: span 1;
  }
}
.component8 {
  display: flex;
  flex-direction: column;
  grid-column: span 1;
  grid-row: span 1;
  @include md {
    order: 9;
  }
}

// Modal
.modal {
  .name {
    @include heading(14px, 500);
    text-align: center;
    width: 100%;
    margin-bottom: 12px;
  }

  .img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .content {
    @include heading;
    margin-bottom: 12px;
  }

  .createAt {
    @include heading;
    color: $subheading-color;
  }
}