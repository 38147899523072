@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.noPermission {
  @include box(unset);
  @include heading(18px, 400);
  text-align: center;
  padding-top: 200px;
  .btn {
    @include primary-btn;
    margin-top: 32px;
  }
}

.box {
  @include  box;
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;

  @include sm {
    flex-direction: column;
  }

  .leftCol {
    display: flex;
    flex-direction: column;
    flex: 0 1 480px;

    @include md {
      flex: 1;
    }

    .imgBox {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px;

      @include sm {
        margin-bottom: 10px;
      }

      .img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .name {
      @extend %cat-name;

      @include sm {
        align-self: flex-start;
        order: -1;
        margin-bottom: 8px;
      }
    }
  }

  .rightCol {
    flex: 1;
  }
}

.text {
  @extend %context;
  margin-top: 16px;
  color: $primary-color;
}

.h1 {
  @include heading(18px, 400);
  margin: 10px 0;
}
.h2 {
  @include heading(14px, 500);
  margin: 10px 0;
}

.btn {
  @include primary-btn;
  margin-top: 16px;
  width: 280px;

  @include sm {
    width: 100%;
  }
}

.note {
  @include heading(14px, 500);
  margin: 20px 0 10px 0;
  color: $special-color;
}

.ps {
  @include heading;
  margin-bottom: 20px;
}

.bgGray {
  background-color: $subheading-color;
}

.row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;

  @include md {
    flex-direction: column;
  }

  .btn {
    width: 100%;
    flex: 1;
  }
}

.row2 {
  display: flex;
  column-gap: 10px;
}

.stats {
  font-weight: 500;
  margin: 0 5px 0 2px;
}

.deleteBtn {
  color: $danger-color;
  display: block;
  cursor: pointer;
}

.downloadBtn {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-decoration: none;
  height: fit-content;
  margin-right: 10px;
}

.contract_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #EEE;
  }
}

.previewItem {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 3px 0;

  .icon {
    color: darken($subheading-color, 20%);
    margin: 0 5px;
  }

  .downloadBtn {
    color: $primary-color;
    flex: 1;

    &:hover {
      color: darken($primary-color, 5%) !important;
    }
  }
}

.designation_modal {
  margin: 32px 0;
  color: $heading-color;
  white-space: pre-wrap;

  :not(:first-child) {
    margin-top: 15px;
  }
}
