@use "src/utils" as *;
@use "src/global" as *;

.infoIcon {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: $heading-color;
  gap: 8px;
}

.yesBtn {
  @include primary-btn;
  width: 80px;
}

.noBtn {
  @include secondary-btn;
  width: 80px;
}

.footer {
  gap: 16px;
}

.content {
  color: $heading-color;
  margin: 32px 0;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 300;
  line-height: 22.4px;
}
