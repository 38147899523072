@use "src/utils" as *;
@use "src/global" as *;

.link {
  text-decoration: none;
}

.card {
  position: relative;
  display: flex;
  padding: 12px;
  gap: 12px;
  background-color: #fff;
  border: 1px solid $border-color;
  max-width: 280px;

  img {
    width: 50px;
    height: 50px;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .name {
    flex: 1;
    color: $primary-color;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
  }

  .text {
    color: #3F3F46;
    font-size: 12px;
    font-weight: 300;
    line-height: 19.2px;
  }

  .failed {
    color: $danger-color;
  }

  .opacity20 {
    opacity: 0.2;
  }

  .spin {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .clear {
    position: absolute;
    top: 10px;
    right: 12px;

    &:active {
      border: none;
    }
  }
}

.clearable {
  @include xxs {
    max-width: unset;
  }
}
