@use "src/utils" as *;
@use "src/global" as *;


.header {
  .row1 {
    display: flex;
    align-items: center;
    margin: 0 16px 15px 16px;
  }

  .row2 {
    display: flex;
    align-items: center;
    margin: 15px 16px 15px 16px;
    flex-wrap: wrap;
    gap: 8px;
  }

  .avatar {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 32px;
      height: 32px;
    }
  }

  .name {
    flex: 1;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    margin: 0 20px;
  }

  .tag {
    @extend %context-light;
    color: $primary-color;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    padding: 8px 12px;
    border-radius: 2px;
    border: 1px solid $primary-color;
    text-wrap: nowrap;
  }

  .grayTag {
    color: $subheading-color;
    border: 1px solid $subheading-color;
  }

  .separator {
    height: 1px;
    background-color: $border-color;
  }

  .button {
    @include primary-btn;

    width: 120px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .groups {
    display: flex;
    flex-grow: 1;
    gap: 0 20px;
    flex-wrap: wrap;

    @include xs {
      gap: 0 12px;
    }
  }

  .group {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    font-weight: 300;
    font-size: 14px;
    line-height: 22.4px;
  }

  .buttons {
    display: flex;
    gap: 0 8px;

    @include xs {
      gap: 0 12px;
      flex: 1;
    }
  }

  .message {
    .icon {
      fill: #fff;
      height: 16px;
      width: 16px;
    }

    @include xs {
      flex: 1;
    }
  }

  .comment {
    @include xs {
      flex: 1;
    }
  }
}
