@use "src/utils" as *;
@use "src/global" as *;

.client_layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1 0;
  display: flex;
  flex-direction: column;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn {
  @include primary-btn;
  width: 70px;
  height: 32px;
  padding: unset;
  margin-top: 16px;
}

.icon {
  color: #52C41A;
  margin-bottom: 16px;
}

.warnIcon {
  color: #ffc53d;
  margin-bottom: 16px;
}

.infoIcon {
  color: $primary-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}


.title {
  color: $heading-color;
  gap: 8px;
}

.yesBtn {
  @include primary-btn;
  width: 80px;
}

.noBtn {
  @include secondary-btn;
  width: 80px;
}

.footer {
  gap: 16px;
}

.content {
  color: $heading-color;
  margin: 32px 0;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 300;
  line-height: 22.4px;
}