@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include  box;
  margin-top: $headerGap;
  display: flex;
  row-gap: 10px;
  column-gap: 10px;

  @include sm {
    flex-direction: column;
  }

  .leftCol {
    flex: 0 1 480px;

    @include md {
      flex: 1;
    }

    .headerBox {
      display: none;

      @include sm {
        display: block;
        margin-bottom: 16px;
      }
    }
  }

  .rightCol {
    flex: 1;
    position: relative;

    .headerBox {
      padding: 0 10px;
      margin-bottom: 16px;

      @include sm {
        display: none;
      }
    }
  }
}

.title {
  @include heading(18px, 400);
  margin: 8px 0 16px 0;
}

.subTitle {
  @include heading(12px, 300);
}

.footerWrapper {
  left: 100%;
  bottom: 0px;
  height: 100px;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 16px;
  padding-bottom: 50px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, .97), rgba(255, 255, 255, .9), rgba(255, 255, 255, .01));
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include sm {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
  }
}

.btn {
  @include primary-btn;
  @include sm {
    width: 100%;
  }
  margin-bottom: 8px;
}

.ticket {
  @extend %context-light;
  color: $primary-color;
}

@mixin tag ($color) {
  @extend %context-light;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid $color;
  text-align: center;
  color: $color;
  width: 160px;

  @include sm {
    width: 100%;
  }
}

.tagEventNotStart {
  @include tag($primary-color);
}

.tagEventEnded {
  @include tag($subheading-color);
}
