@use "src/utils" as *;
@use "src/global" as *;
@use "../../styles.module.scss" as *;

.imgCard {
  background: var(--src) center center / cover no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 348px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.caption {
  background-color: $bg_color_type_1;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  height: 71px;
  left: 0px;
  opacity: 0.8;
  position: absolute;
  right: 0px;

  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  gap: 4px 0px;
  margin: 8px 16px 7px;
  position: relative;
}

.title {
  margin: 0;
  color: $color_type_0;
  font: $font_type_0;
  letter-spacing: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  color: $color_type_0;
  font: $font_type_1;
  letter-spacing: 0px;
}
