@use "src/utils" as *;
@use "src/global" as *;


.header {
  @include main;
  background-color: $background-color;


  .head_box {
    @include box(unset);
    background-color: $background-color;
    padding-bottom: 32px;

    .stickerBox {
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      column-gap: 10px;
      row-gap: 10px;
      flex-wrap: wrap;

      .sticker {
        width: 100%;
        height: 80px;
        max-width: 380px;
        padding: 16px 20px;
        display: flex;
        background-color: #FFF;
        box-shadow: 0px 10px 18px 0px #091E4226;
        align-items: center;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0px;
        text-decoration: none;
        color: $heading-color;
        box-sizing: border-box;

        @include sm {
          max-width: unset;
          height: unset;
        }

        .contentTicket {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .contentUser {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include tn {
            flex-wrap: wrap;
          }

          .title {
            margin-right: 30px;
          }
        }

        .light {
          color: #A8A29E;
        }

        .primary {
          color: #00A5B8;
          font-weight: 700;
          font-size: 32px;
        }

        .blueTxt {
          color: #00A5B8;
          text-wrap: nowrap;
        }

        .arrow {
          color: #00A5B8;
          margin-left: 10px;
        }

      }
    }

    .squareBox {
      display: flex;
      column-gap: 32px;
      row-gap: 32px;

      @include sm {
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
      }

      .squareRow {
        display: flex;
        width: 100%;
        column-gap: 32px;

        @include sm {
          column-gap: 10px;
        }
      }

      .square {
        text-decoration: none;
        width: 100%;
        height: 200px;
        background-color: #00A5B8;
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 10px 18px 0px #091E4226;
        font-family: Noto Sans TC;
        border-radius: 2px;

        @include sm {
          height: 140px;
        }


        .squareNum {
          font-family: Roboto;
          font-size: 64px;
          font-weight: 600;
        }

        .squareUnit {
          font-size: 14px;
          margin-left: 10px;
          font-weight: 300;
        }

        .iconWrapper {
          position: relative;
          width: 70px;
          height: 70px;

          .icon {
            fill: white;
            width: 100%;
            height: 100%;
          }
        }

        .unread {
          position: absolute;
          top: 0;
          right: -5px;
          background-color: #CC3300;
          width: 20px;
          height: 20px;
          border: 5px solid #fff;
          border-radius: 50%;
        }

        .squareBottom {
          font-size: 18px;
          margin-top: 10px;
        }
      }

      .help {
        background-color: #FFF;
        color: #00A5B8;
        border: 3px solid #00A5B8;

        .squareBottom {
          margin-top: 20px;
        }
      }
    }

    .descBox {
      margin: 20px 0;

      .desc {
        @extend %context;
      }
      .desc_bold {
        @include heading(14px, 700);
      }
    }

    .others {
      display: flex;
      gap: 30px;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        max-width: 160px;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
      }
    }
  }
}

.main {
  @include main;

  .box {
    @include box(unset);
  }

  .subtitle {
    @include heading(18px, 400);
    padding-top: 30px;
  }

  .card_box {
    display: grid;
    padding-top: 30px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    column-gap: 16px;
    row-gap: 50px;

    @include sm {
      grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    }

    .card_content {
      color: #A8A29E;
    }

    .card_footer {
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      .blue {
        color: $primary-color;
      }

      .red {
        color: #B91C1C;
      }

      .card_footer_left {
        flex: 1;
        white-space: nowrap;
      }
    }

    .card_btn {
      @include secondary-btn;
      width: 80px;
      height: 32px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .noCatBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;

    .noCat {
      font-family: Noto Sans TC;
      font-size: 28px;
      font-weight: 300;
      line-height: 45px;
      letter-spacing: 1px;
      text-align: center;
      color: $heading-color;
    }
  }
}