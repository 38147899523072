@use "src/utils" as *;
@use "src/global" as *;

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form {
    max-width: 1000px;
    width: 50%;
    min-height: 600px;
  }
}

.application {
  overflow-x: hidden;
  padding-top: 2px;

  .cardBox {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    column-gap: 30px;
    row-gap: 50px;
    max-width: 1800px;
    padding: 0 2px;
  }

  .cardContent {
    font-family: Noto Sans TC;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #A8A29E;
  }
}


.comment {

}
