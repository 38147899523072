@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.noPermission {
  @include box;
  @include heading(18px, 400);
  text-align: center;
  padding-top: 200px;
}

.btn {
  @include primary-btn;
  margin-top: 32px;
}

