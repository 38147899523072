@use "src/utils" as *;
@use "src/global" as *;

.actions {
  display: flex;
  align-items: center;
  max-width: 880px;
  /* max-width - margin (140px) */
  width: calc(880px - 280px);
  margin: 50px auto 37px auto;

  @include sm {
    width: 80%;
  }

  .keyword {
    flex: 1;
  }

  .search {
    width: 160px;
    background-color: $primary-color;
    color: white;
    font-weight: 400;
    font-size: 12px;
    border: none;
    margin-left: 12px;
    border-radius: 2px;

    &:hover {
      background-color: darken($primary-color, 5%) !important;
    }

    @include sm {
      width: 100px;
    }
  }
}

.list {
  width: 100%;
  max-width: 880px;
  flex-shrink: 0;
  align-self: center;

  @include lg {
    width: 80%;
  }
}

.empty {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }

  .hint {
    color: $heading-color;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
  }
}
