
.cropContainer {
  width: 100%;
  height: 40vh;
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}