@use "src/utils" as *;
@use "src/global" as *;

.main {
  background-color: $background-color;
}

.boxWrapper {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.box {
  min-height: 400px;
  background-color: #FFF;
  width: min(880px, 90vw);
  white-space: pre-wrap;
  padding: 40px 32px;

  @include heading(14px, 300);

  li {
    margin-left: 16px;
    display: list-item;
  }
}

.title {
  @include heading(28px, 300);
  margin-bottom: 30px;
}

.subtitle {
  @include heading(14px, 500);
  margin: 20px 0;
}

.updateAt {
  margin-top: 20px;
}