@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;

  .box {
    @include box(unset);
    padding-top: $headerGap;
  }
}

.card_box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  column-gap: 16px;
  row-gap: 50px;

  @include sm {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
  }

  .card_content {
    font-family: Noto Sans TC;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #A8A29E;
  }

  .time {
    color: $primary-color;
  }
}

.noCatBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  .noCat {
    font-family: Noto Sans TC;
    font-size: 28px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    color: $heading-color;
  }
}