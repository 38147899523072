@use "src/utils" as *;
@use "src/global" as *;

.card {
  position: relative;
  display: flex;
  padding: 12px 24px 12px 12px;
  gap: 12px;
  background-color: #fff;
  border: 1px solid $border-color;
  align-self: flex-start;

  @include xxs {
    align-self: unset;
  }

  img {
    width: 50px;
    height: 50px;
    align-self: center;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .name {
    flex: 1;
    color: $primary-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
  }

  .text {
    color: #3F3F46;
    font-size: 12px;
    font-weight: 300;
    line-height: 19.2px;
    margin-top: 4px;
  }

  .spin {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .failed {
    color: $danger-color;
  }

  .opacity20 {
    opacity: 0.2;
  }

  .clear {
    position: absolute;
    top: 10px;
    right: 12px;

    .x {
      fill: $subheading-color;
    }

    &:active {
      border: none;
    }
  }
}

.clearable {
  padding-right: 55px;
}

.pointer {
  cursor: pointer;
}
