@use "src/utils" as *;
@use "src/global" as *;


.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
}

.link {
  color: $primary-color;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.commentBox {
  @extend %context;
  padding: 16px;
  border: 1px solid $border-color;
  border-top: 4px solid $border-color;
  margin-top: 30px;

  .solidDivider {
    width: 100%;
    border-bottom: 1px solid $border-color;
    margin: 16px 0;
  }
  .dottedDivider {
    width: 100%;
    border-bottom: 1px dashed $border-color;
    margin: 16px 0;
  }

  .title {
    @include heading(14px, 500);
  }

  .subtitle {
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
  }

  .content {
    color: $subheading-color;
  }
}

.tag {
  border: 1px solid $subheading-color;
  color: $subheading-color;
  padding: 4px 8px;
  border-radius: 2px;
  width: fit-content;
  font-size: 12px;
}

.blueTag {
  border: 1px solid $positive-comment-color;
  color: $positive-comment-color;
}

.blue {
  color: $positive-comment-color;
}

.orange {
  color: $negative-comment-color;
}

.unSelect {
  color: $unselect-color;
}

.gap {
  height: 16px;
}