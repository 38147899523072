@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include  box;
  margin-top: $headerGap;
  max-width: 880px;
  @extend %context;
}

.formGap {
  margin-bottom: 24px;
}

.fieldGap {
  padding-bottom: 8px;
}

.extra {
  @extend %context-light;
  color: $special-color;
}

.text {
  @extend %context-light;
}

.link {
  @extend %context-light;
  color: $primary-color;
  text-decoration: underline;
  display: inline-block;

  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}

.primaryBtn {
  @include primary-btn;
  margin-top: 16px;
  display: block;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}