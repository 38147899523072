@use "src/utils" as *;
@use "src/global" as *;

.button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px !important;

  .icon {
    fill: white;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: darken($primary-color, 5%) !important;
  }
}
