@use "src/utils" as *;
@use "src/global" as *;

$bg_color_type_0: rgb(245, 245, 245);

.main-image {
  aspect-ratio: 2.4;
  width: 100%;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  outline: 6px solid rgb(216, 191, 169);
  outline-offset: -6px;
  position: relative;
}


.banner-section {
  @include main;
  background-color: $bg_color_type_0;
  display: flex;
  flex-direction: column;
  position: relative;
}
.banner-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.carousal_container {
  display: flex;
  flex-direction: column;
  margin: 20px auto 28px;
  max-width: 1440px;
  position: relative;
  width: 100%;
  @include xl {
    aspect-ratio: auto;
  }
  @include md {
    margin: 20px 24px 28px;
    width: auto;
  }
  @include xxs {
    margin: 20px 16px 28px;
  }
}
.carousal {
  display: flex;
  flex-direction: column;
}
.carousal-navigation {
  bottom: -8px;
  height: 4px;
  left: 0px;
  margin: 0px auto;
  object-fit: cover;
  position: absolute;
  right: 0px;
  width: 23.26%;
}
.dot {
  position: absolute !important;
  bottom: -15px !important;
}