@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include  box;
  max-width: 860px;
  margin-top: $headerGap;
}


.commentBox {
  @extend %context;
  margin-top: 30px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .title {
    @include heading(14px, 500);
  }

  .subtitle {
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
  }

  .content {
    color: $subheading-color;
  }
}

.tag {
  border: 1px solid $subheading-color;
  color: $subheading-color;
  padding: 8px 12px;
  border-radius: 2px;
  width: fit-content;
}

.blueTag {
  border: 1px solid $positive-comment-color;
  color: $positive-comment-color;
}

.blue {
  color: $positive-comment-color;
}

.orange {
  color: $negative-comment-color;
}

.black {
  color: $heading-color !important;
}

.unSelect {
  color: $unselect-color;
}

.solidDivider {
  width: 100%;
  border-bottom: 1px solid $border-color;
  margin-bottom: 15px;
  margin-top: 30px;
}
.dottedDivider {
  width: 100%;
  border-bottom: 1px dashed $border-color;
  margin: 15px 0;
}

.date {
  @extend %context;
  color: $subheading-color;
  margin-top: 20px;
}

.replyBox {
  color: $subheading-color;

  .content {
    @extend %context;
    color: $subheading-color;
    margin-bottom: 20px;
  }

  .footer {
    @extend %context;
    color: $subheading-color;
    display: flex;
    justify-content: space-between;
  }
}

.createBtn {
  @include primary-btn;
  left: 100%;
  bottom: 50px;
  position: sticky;
  @include sm {
    width: 100%;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .input {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .btn {
    @include primary-btn;
  }
}

.disclaimer {
  @include heading(14px, 500);
  color: $subheading-color;
  margin-bottom: 16px;
}

.noCommentBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;

  .noComment {
    font-family: Noto Sans TC;
    font-size: 28px;
    font-weight: 300;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    color: $heading-color;
  }
}