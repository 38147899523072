@use "src/utils" as *;
@use "src/global" as *;

.box {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 30px 0;
  cursor: pointer;

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .defaultAvatar {
    width: 32px;
    height: 32px;
  }

  .avatarWrapper {
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #BFBFBF;
  }

  .unread {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #FF4D4F;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid #fff;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    gap: 5px;
    flex: 1;
    overflow: hidden;
  }

  .name {
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .message {
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .readed {
    color: #A8A29E;
  }

  .timestamp {
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    color: #A8A29E;
  }
}

.separator {
  border-bottom: 1px solid $border-color;
}
