@use "src/utils" as *;
@use "src/global" as *;


.panel {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .actions {
    display: flex;
    gap: 10px;

    .button {
      border-radius: 4px;
    }
  }

  .tips {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    color: $special-color;
    margin: 12px 0 0 0;
  }

  .input {
    margin: 12px 0 22px 0;
  }

  .foster {
    padding: 15px 0;
  }

  .files {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .images {
    padding: 15px 0;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  .send {
    width: 140px;
    margin-top: 46px;
    align-self: center;
    border-radius: 2px;
    background-color: $primary-color;

    &:hover {
      background-color: darken($primary-color, 5%) !important;
    }
  }

  .disabled {
    background-color: $unselect-color;
    cursor: not-allowed;

    &:hover {
      background-color: $unselect-color !important;
    }
  }
}
