@use "src/utils" as *;
@use "src/global" as *;

.main {
  @extend %context;
  margin-top: 20px;

  .title {
    @include heading(14px, 500);
    margin-bottom: 10px;
  }

  .subtitle {
    display: flex;
    column-gap: 10px;
    margin-bottom: 10px;
  }

  .content {
    border-bottom: 1px solid $border-color;
    margin-bottom: 10px;
    padding-bottom: 10px;
    color: $subheading-color;
  }
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .btn {
    color: $primary-color;
    text-decoration: none;
  }
}

.blue {
  color: $positive-comment-color;
}

.orange {
  color: $negative-comment-color;
}

.unSelect {
  color: $unselect-color;
}