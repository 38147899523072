@use "src/utils" as *;
@use "src/global" as *;

.main {
  @include main;
}

.box {
  @include box;
  text-align: center;
  padding-top: 200px;
}
