.ribbon {
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .label {
    color: #fff;
    font-family: Noto Sans TC;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    transform: rotate(45deg) translateY(-100%);
    transform-origin: center;
    position: absolute;
    line-height: 1.15;
  }
}
