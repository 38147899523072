@use "src/utils" as *;
@use "src/global" as *;

.reaction {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;

  @include sm {
    top: 4px;
    right: 4px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    position: relative;
    padding: 4px 12px;

    @include sm {
      padding: 4px 8px;
    }
  }

  .background {
    position: absolute;
    background-color: $primary-color;
    opacity: 0.8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .icon {
    width: 40px;
    height: 40px;
    z-index: 1;

    @include sm {
      width: 28px;
      height: 28px;
    }
  }

  .label {
    z-index: 1;
    margin-left: 4px;
    color: white;
    font-size: 14px;
    font-weight: 300;
  }

  .active {
    fill: white;
  }
}

.small {
  .wrapper {
    padding: 4px 8px;
  }

  .icon {
    width: 28px;
    height: 28px;
  }
}
